import styled from '@emotion/styled'
import { Line } from 'app/components/Common/Line'
import { IconLogo } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { uniqBy } from 'lodash'
import React, { memo } from 'react'

import { Offer, Props as OfferProps } from './Offer'

export interface Props {
  description?: string
  label?: string
  languageCode: string
  offers: OfferProps[]
  title?: string
}

export const OffersList = memo(function OffersList({
  description,
  label,
  languageCode,
  offers,
  title,
}: Props) {
  if (offers.length < 1) {
    return (
      <>
        <ContainerAlt>
          <IconLogo />

          {label ? <Label>{label}</Label> : null}
          {title ? (
            <Title>
              {title}
              <Line />
            </Title>
          ) : null}
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
        </ContainerAlt>
        <Message>
          <IconLogo />
          <Description>
            {useVocabularyData('no-offers-message', languageCode)}
          </Description>
        </Message>
      </>
    )
  }

  return (
    <Container>
      <IconLogo />

      {label ? <Label>{label}</Label> : null}
      {title ? (
        <Title>
          {title}
          <Line />
        </Title>
      ) : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}

      <Offers row wrap>
        {uniqBy(offers, 'title').map((item, index) => (
          <Offer key={index} {...item} />
        ))}
      </Offers>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.25rem 0 12.5rem;
  padding: 0 10.556vw;
  text-align: center;

  > svg {
    width: 4.9375rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin-bottom: 3.25rem;
  }

  @media (max-width: 1199px) {
    margin: 8.125rem 0 7.5rem;
    padding: 0 1.875rem;

    > svg {
      width: 3.75rem;
      margin-bottom: 2.25rem;
    }
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`
const ContainerAlt = styled.section`
  margin: 9.25rem 0 6.25rem;
  padding: 0 10.556vw;
  text-align: center;

  > svg {
    width: 4.9375rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin-bottom: 3.25rem;
  }

  @media (max-width: 1199px) {
    margin: 8.125rem 0 3.75rem;
    padding: 0 1.875rem;

    > svg {
      width: 3.75rem;
      margin-bottom: 2.25rem;
    }
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Label = styled.h1`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-bottom: 0.9375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;

  > div {
    margin: 1.875rem auto 0;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1.25rem;
    }
  }
`

const Description = styled.div`
  max-width: 46.875rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.875rem;
  margin: 3.25rem auto 0;

  @media (max-width: 1199px) {
    font-size: 1rem;
    line-height: 1.75rem;
    margin-top: 2.25rem;
  }
`

const Offers = styled(FlexBox)`
  margin: 1.25rem -5.625rem 0 0;

  > a {
    width: calc(50% - 5.625rem);
    margin: 5rem 5.625rem 0 0;
  }

  @media (max-width: 767px) {
    margin: 0;

    > a {
      width: 100%;
      margin-right: 0;
    }
  }
`

const Message = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 11.625rem 10.556vw;
  text-align: center;

  > svg {
    width: 4.9375rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralLight2};
  }

  @media (max-width: 1199px) {
    padding: 7.5rem 1.875rem;

    > svg {
      width: 3.75rem;
      margin-bottom: 2.25rem;
    }
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`
